import React from "react";
import { useEffect, useState } from "react";
import ProductList from "./Products";
import VendorsList from "./Vendors";
import RegisteredUsersList from "./Users";
import CustomerBarchart from "./CustomerBarchart";
import ProductSoldPieChart from "./ProductSoldPieChart";
import swal from 'sweetalert';
import CustomerLocationDetails from './CustomerLocation';
const DashBoard = () => {
  const [dashboard, setDashboard] = useState();
  const [feedback, setFeedback] = useState();
  const [dom, setDom] = useState("userDashboard");


  const mappedUserDashboard = async () => {
    swal({
      title: "Loading ...",

      buttons: false,


    });
    const dashboardResponse = await fetch("https://api.dailymoo.in/v2/promo/dashboard?type=user_dashboard_data")
    const dashboardData = await dashboardResponse.json()
   swal.close();
    setDashboard(dashboardData.response)
    setDom("userDashboard");
  }

  const mappedFeedbackDetails = async () => {
    swal({
      title: "Loading ...",

      buttons: false,


    });
    const feedbackResponse = await fetch("https://api.dailymoo.in/v2/promo/dashboard?type=feedback")
    const feedbackData = await feedbackResponse.json()
    swal.close();
    setFeedback(feedbackData.response)
    setDom("feedback")
  }

  useEffect(() => {
    
    
    mappedUserDashboard();
    

  }, [])
  
  // console.log(dom)

  const handleClick = () =>{
    setDom("products")
  }
  const handleVendorClick=()=>{
    setDom("vendors")
  }
  const handleUserClick=()=>{
    setDom("users")
  }

  return (
    <div >
      <div className="row fix-dashboard-header">
        <div className="col-lg-12 header">
          <h2>Dashboard</h2>
        </div>
      </div>
      <div className="row">
        <div className="w3-sidebar w3-light-grey w3-bar-block" >
          <button className={dom === "userDashboard" ? "w3-bar-item w3-button button-active" : "w3-bar-item w3-button"} onClick={mappedUserDashboard}>User Dashboard</button>
          <button className={dom === "feedback" ? "w3-bar-item w3-button button-active" : "w3-bar-item w3-button"} onClick={mappedFeedbackDetails}>Feedbacks</button>
          <button className={dom === "users" ? "w3-bar-item w3-button button-active" : "w3-bar-item w3-button"} onClick={handleUserClick}>Users</button>
          <button className={dom === "products" ? "w3-bar-item w3-button button-active" : "w3-bar-item w3-button"} onClick={handleClick} >Products</button>
          <button className={dom === "vendors" ? "w3-bar-item w3-button button-active" : "w3-bar-item w3-button"} onClick={handleVendorClick} >Vendors</button>
        </div>
      </div>
      {dashboard && dom === "userDashboard" && <div className="row bindResponse">
        <div className="col-lg-4 card-new blue">Total Amount Paid To Users : Rs. {dashboard.totalAmountPaidToCustomers}</div>
        <div className="col-lg-4 card-new purple">Total Amount Paid To Vendors : Rs. {dashboard.totalAmountPaidToOutlets}</div>
        <div className="col-lg-4 card-new violet">Total Sold Amount : Rs. {dashboard.totalPurchasedAmount}</div>
        <div className="col-lg-4 card-new green">No. Of Users Reedemed : {dashboard.totalCustomersCount}</div>
        <div className="col-lg-4 card-new violet">No. Of Vendors : {dashboard.totalVendersCount}</div>
        <CustomerBarchart /> <ProductSoldPieChart /> <CustomerLocationDetails/></div>}
      {feedback && dom === "feedback" && <div className="row bindResponse" >
        {
          feedback.map((item, index) => {
            return (
              <div className="col-lg-4 card " key={index}>
                <p className="card__title" style={{float:'left'}}>{item.feedback}</p>
                {(() => {
                  switch (item.rating) {
                    case 1:
                      return <span className="hint-star star" >
                        <p><i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star-o" aria-hidden="true"></i>
                          <i className="fa fa-star-o" aria-hidden="true"></i>
                          <i className="fa fa-star-o" aria-hidden="true"></i>
                          <i className="fa fa-star-o" aria-hidden="true"></i></p></span>;
                      break;
                    case 2:
                      return <span className="hint-star star" >
                        <p><i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star-o" aria-hidden="true"></i>
                          <i className="fa fa-star-o" aria-hidden="true"></i>
                          <i className="fa fa-star-o" aria-hidden="true"></i></p></span>;
                      break;
                    case 3:
                      return <span className="hint-star star" >
                        <p><i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star-o" aria-hidden="true"></i>
                          <i className="fa fa-star-o" aria-hidden="true"></i></p></span>;
                      break;
                    case 4:
                      return <span className="hint-star star" >
                        <p><i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star-o" aria-hidden="true"></i></p></span>;
                      break;
                    case 5:
                      return <span className="hint-star star" >
                        <p><i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star" aria-hidden="true"></i>
                          <i className="fa fa-star" aria-hidden="true"></i></p></span>;
                      break;
                    default:
                  }
                })()}

              </div>
            )
          })
        }
      </div>}
     {dom === "products" && <ProductList />}

     {dom === "vendors" && <VendorsList />}
     {dom === "users" && <RegisteredUsersList />}
    
    </div>
  );
}
export default DashBoard;