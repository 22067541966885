import React from "react";
import { useEffect, useState } from "react";
import  swal from 'sweetalert';


const LoginDashboard = () => {

  const [loginDetails, setloginDetails] = useState({
    username: "",
    password: ""
  });
  const onFielsValueChange = (e) => {

    setloginDetails({
      ...loginDetails,
      [e.target.name]: e.target.value
    })
  }
  const routeChange = () => {
    if (loginDetails.username == "admin" && loginDetails.password == "promo@123") {
      window.location.href = "/Dashboard";
    } else {
      swal("Invalid Credentials", "", "error");
      swal.close();
    }


  
  }



  return (
    <div>

      <div className="col-lg-12" style={{ marginTop: "20px" }}>
        <div className="col-lg-4">
        </div>
        <div className="col-lg-4 login-div">
          <p className="avatar-image"> <img src={require("../Images/img_avatar.jpg")} ></img></p><br></br>
          <input type="text" name="username" value={loginDetails.username} onChange={onFielsValueChange} placeholder="User Name" className="form-control" /><br></br>
          <input type="password" name="password" placeholder="Password" value={loginDetails.password} onChange={onFielsValueChange} className="form-control" /><br></br>
          <button type="button" onClick={routeChange} className="btn btn-admin-login" style={{ marginBottom: "20px" }}>Login</button>


        </div>
        <div className="col-lg-4 ">

        </div>

      </div>

    </div>
  );
}
export default LoginDashboard;