import React from "react";
import { useEffect, useState } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import swal from 'sweetalert';



const RegisteredUsersList = () => {
const [registeredUsers, setUsers] = useState();
const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;
const columns = [
  { dataField: 'name', text: 'Customer Name', sort: true },
  { dataField: 'phone_number', text: 'Phone No.' },
  { dataField: 'purchase_amount', text: 'Purchased Amount (In Rs.)' },
  { dataField: 'creationDate', text: 'Date' },

];
const pagination = paginationFactory({
  page: 1,
  sizePerPage: 10,
  lastPageText: '»',
  firstPageText: '«',
  nextPageText: '>',
  prePageText: '<',
  showTotal: true,
  alwaysShowAllBtns: true,

});


 const mappedRegisteredUsers =async () => { 
  swal({
    title: "Loading ...",

    buttons: false,


  });
  const userResponse = await fetch('https://api.dailymoo.in/v2/promo/dashboard?type=registered_users_details');
  const usersData = await userResponse.json();
swal.close();
  setUsers(usersData.response);

 }

 useEffect(() => {
  mappedRegisteredUsers();
}, [])



return <div className='row tableResponse'>
{registeredUsers && <ToolkitProvider
  keyField="phone_number"
  data={registeredUsers}
  columns={columns}
  search
  exportCSV
>
  {
    props => (
      <div>

        <SearchBar {...props.searchProps} />
        <ExportCSVButton className="btn btn-success" {...props.csvProps}>CSV</ExportCSVButton>
        <hr />
        <BootstrapTable
          pagination={pagination}
          {...props.baseProps}
        />
      </div>
    )
  }
</ToolkitProvider>}

</div>
}

export default RegisteredUsersList;