import React from "react";
import { useEffect, useState } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import swal from 'sweetalert';




const ProductList = () => {

  const [products, setProducts] = useState();

  const showImage = (cell, row) => {


    return (
      <img src={row.image_name} style={{ width: '50px', height: '50px' }}></img>
    );
  }

  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;


  const columns = [
    { dataField: 'product_id', text: 'Product Id', sort: true },
    { dataField: 'image_name', text: 'Icon', formatter: showImage },
    { dataField: 'product_name', text: 'Product Name' },
    { dataField: 'price', text: 'Price (In Rs.)' },
    { dataField: 'quantity_sold', text: 'Quantity Sold' },
    { dataField: 'amount_sold', text: 'Amount Sold (In Rs.)' }
  ];
  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    lastPageText: '»',
    firstPageText: '«',
    nextPageText: '>',
    prePageText: '<',
    showTotal: true,
    alwaysShowAllBtns: true,

  });

  const mappedSoldProducts = async () => {
    swal({
      title: "Loading ...",
  
      buttons: false,
  
  
    });
    const productsResponse = await fetch("https://api.dailymoo.in/v2/promo/dashboard?type=soldProductsDetails");
    const productsData = await productsResponse.json();
    swal.close();
    setProducts(productsData.response);


  }
  useEffect(() => {
    mappedSoldProducts();

  }, [])
  //console.log(products)
  return <div className='row tableResponse'>


    {products && <ToolkitProvider
      keyField="product_id"
      data={products}
      columns={columns}
      search
      exportCSV
    >
      {
        props => (
          <div>

            <SearchBar {...props.searchProps} />
            <ExportCSVButton className="btn btn-success" {...props.csvProps}>CSV</ExportCSVButton>
            <hr />
            <BootstrapTable
              pagination={pagination}
              {...props.baseProps}
            />
          </div>
        )
      }
    </ToolkitProvider>}
  </div>
}

export default ProductList;