import './App.css';
import LoginDashboard from "./Login";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import DashBoard from './Dashboard';




function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LoginDashboard />} />
          <Route path="/Dashboard" element={<DashBoard />} />


        </Routes>
      </BrowserRouter>
     
    </div>


  );
}

export default App;
