import React from 'react';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import { useEffect, useState } from "react";
import moment from 'moment';



const CustomerBarchart = () => {  
const [barChartDetails, setbarChartDetails] = useState({
  dateDetails :[],
  customerDetails :[],
  soldAmount :[],

})

 var customerArr = [],
 dateArr = [],
 soldAmountArr = [];

 const getBarchatData = async () => {
  const customerBarchartResponse = await fetch("https://api.dailymoo.in/v2/promo/dashboard?type=dateWiseCustomerJoined");
  const barChatData = await customerBarchartResponse.json()

  for (let i = 0; i < 30; i++) {
    let date = moment();
    date.subtract(i, 'day');
    if (date.format('YYYY-MM-DD') < '2022-03-10') break;
    dateArr.push(date.format('YYYY-MM-DD'));
  }
  dateArr = dateArr.reverse();
  dateArr.forEach((value, index) => {
    customerArr.push(barChatData.response.dateWisecustomerDetails[value] ?? 0);
    soldAmountArr.push(barChatData.response.dateWiseSell[value] ?? 0);
  });
  // setdateDetails(dateArr);
  // setcustomerDetails(customerArr);
  // setsoldAmount(soldAmountArr);
  setbarChartDetails({dateDetails:dateArr,customerDetails:customerArr,soldAmount:soldAmountArr});
 }
   useEffect(() => {
    getBarchatData();
  }, [])

const chartData = {
  labels: barChartDetails.dateDetails,
  datasets: [{
      label: 'No of Customers',

      backgroundColor: 'rgb(255, 99, 132)',
      borderColor: 'rgb(255, 99, 132)',
      pointStyle: 'circle',
      pointRadius: 10,
      pointHoverRadius: 15,
      data: barChartDetails.customerDetails
    },
    {
      label: 'Sold Amount',

      backgroundColor: '#1ed5e6',
      borderColor: '#1ed5e6',

      data: barChartDetails.soldAmount
    }

  ]
}

return (
  <div className="col-lg-12 ">
  <div className="col-lg-1"></div>
  <div className="col-lg-10 piechart">
   {barChartDetails &&<Bar 
      data={chartData}

    />}
  </div>
  <div className="col-lg-1"></div>
  </div>
);

}

export default CustomerBarchart;