import React from "react";
import { useEffect, useState } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import swal from 'sweetalert';



const VendorsList = () => {
  const amountPaid = (cell, row) => {
    if (row.is_paid == '0') {
      return <span >{cell} 0</span>
    } else {
      return <span >{cell} {row.amount}</span>
    }


  }
  const csvFormatteramountPaid = (cell, row) => {
    if (row.is_paid == '0') {
      return 0
    } else {
      return  row.amount
    }


  }
  const pendingAmount = (cell, row) => {
    if (row.is_paid == '0') {
      return <span >{cell} {row.amount}</span>
    } else {
      return <span >{cell} {row.customer_count - row.amount}</span>
    }


  }
  const csvFormatterpendingAmount = (cell, row) => {
    if (row.is_paid == '0') {
      return row.amount
    } else {
      return row.customer_count - row.amount
    }
  }
  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;

  const [vendors, setvendors] = useState();
  const columns = [
    { dataField: 'shop_name', text: 'Shop Name', sort: true },
    { dataField: 'outlet_id', text: 'Outlet ID' },
    { dataField: 'sold_amount', text: 'Sold Amount (In Rs.)' },
    { dataField: 'customer_count', text: 'Total Customers' },
    { dataField: 'amount_paid', text: 'Amount Paid (In Rs.)', formatter: amountPaid,
    csvFormatter: csvFormatteramountPaid },
    { dataField: 'pending_amount', text: 'Pending Amount (In Rs.)', formatter: pendingAmount,csvFormatter: csvFormatterpendingAmount },
    { dataField: 'upi_id', text: 'Upi Id' }
  ];
  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    lastPageText: '»',
    firstPageText: '«',
    nextPageText: '>',
    prePageText: '<',
    showTotal: true,
    alwaysShowAllBtns: true,

  });
  const mappedVenderDetails = async () => {
    swal({
      title: "Loading ...",
  
      buttons: false,
  
  
    });
    const vendorsResponse = await fetch("https://api.dailymoo.in/v2/promo/dashboard?type=venderDetails");
    const vendorsData = await vendorsResponse.json();
    swal.close();
    setvendors(vendorsData.response);
  }

  useEffect(() => {
    mappedVenderDetails();
  }, [])

  return <div className='row tableResponse'>
    {vendors && <ToolkitProvider
      keyField="outlet_id"
      data={vendors}
      columns={columns}
      search
      exportCSV
    >
      {
        props => (
          <div>

            <SearchBar {...props.searchProps} />
            <ExportCSVButton className="btn btn-success" {...props.csvProps}>CSV</ExportCSVButton>
            <hr />
            <BootstrapTable
              pagination={pagination}
              {...props.baseProps}
            />
          </div>
        )
      }
    </ToolkitProvider>}

  </div>

}

export default VendorsList;