import react from 'react';
import { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

const CustomerLocationDetails = () => {
  const [customerLocation, setcustomerLocation] = useState([]);


  var centerIndex = '';
  const mappedChartData = async () => {

    const response = await fetch(`https://api.dailymoo.in/v2/promo/dashboard?type=customerLocation`);

    let data = await response.json();

    setcustomerLocation(data.response);

  }

  useEffect(() => {
    mappedChartData()
  }, [])

  return (

<div className="col-lg-12 ">
<div className="col-lg-1"></div>
<div className="col-lg-10 locationMap">
<MapContainer center={[20.3358, 85.8215]} zoom={12}>
        <TileLayer
          url="https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}"
        />
        {customerLocation.length > 0 && customerLocation.map((d, index) => (
        
          <Marker key={index} position={[d.lat,d.lng]}>
             <Popup>{d.customerName }<br></br>{ d.phone_number}</Popup>
          </Marker>
         ))};
          
      </MapContainer>
     
</div>
<div className="col-lg-1"></div>
</div>
)
}

export default CustomerLocationDetails;
